section.home .homeBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: var(--margin);
  gap: var(--margin);
}

section.home .homeBlock .text {
  display: flex;
  flex-direction: column;
  gap: var(--spacer);
  text-align: left;
  height: 100%;
  width: 50%;
}
section.home .homeBlock img {
  height: 50rem;
  width: 50%;
  object-fit: cover;
}

section.home .homeBlock:not(:last-child) {
  padding-bottom: var(--margin);
}

@media only screen and (max-width: 1150px) {
  section.home .homeBlock {
    flex-direction: column-reverse;
    padding-inline: 0;
  }

  section.home .homeBlock img {
    width: 100%;
  }

  section.home .homeBlock .text {
    width: calc(100% - (2 * var(--margin)));
  }
}

section.home .banner {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-height: calc(100svh - 7.5rem);
  height: 90rem;
}

section.home .banner .bannerImage {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  section.home .banner .text {
    background-color: rgba(255, 255, 255, 0.8);
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    padding-inline: var(--spacer);
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
  }

  section.home .banner .text img {
    width: 60%;
    height: auto;
    position: relative;
    object-fit: contain;
  }

@media only screen and (max-width: 1150px) {
  section.home .banner {
    flex-direction: column;
    align-items: flex-start;
  }

  section.home .banner .text {
    width: 100%;
    box-sizing: border-box;
    height: 50%;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  section.home .banner {
    max-height: none !important;
  }
}

   @keyframes slide-in {
    from {
        transform: translateX(100vw);
    }

    to {
      transform: translateX(0vw);

    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0vw);
    }

    to {
      transform: translateX(100vw);
    }
  }

  .slide-in {
    animation-name: slide-in;
    animation-duration: 500ms;
    animation-timing-function: ease;
  }

  .slide-out {
    animation-name: slide-out;
    animation-duration: 500ms;
    animation-timing-function: ease;
  }

  section.home .blogPosts {
  display: flex;
  box-sizing: border-box;
  gap: var(--spacer);
  margin-top: calc(0.5 * var(--margin));
  margin-bottom: var(--margin);
  width: 100%;
  padding-inline: var(--margin);
}

section.home .blogPosts .thumbnail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
  gap: var(--spacer);
}

section.home .blogPosts .thumbnail .title {
  font-size: var(--fs-36);
  color: var(--clr-navy);
  font-weight: 400;
}

section.home .blogPosts .thumbnail .snippet {
  font-size: var(--fs-24);
  line-height: var(--fs-36);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Number of lines */
  -webkit-box-orient: vertical;
} 


section.home .blogPosts .thumbnail .button {
  background-color: var(--clr-lightblue);
  color: var(--clr-navy);
}

@media only screen and (max-width: 1150px) {
  section.home .blogPosts {
      flex-wrap: wrap;
      gap: var(--margin);
      flex-grow: 1;
  }
}

@media only screen and (max-width: 850px) {
  section.home .blogPosts {
      flex-direction: column;
      margin-block: var(--margin);
  }
}