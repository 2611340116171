.post-body .text{
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
    padding: var(--margin);
}

.post-body .text p:not(:last-child) {
    margin-bottom: var(--spacer);
}

.post-body .text .button {
    margin-top: var(--spacer)
}

.post-body .text h1 {
    color: var(--clr-navy);
}

.post-body .text img {
    margin-inline: auto;
}