  .contactBlock {
      padding-inline: var(--margin);
      margin-block: var(--margin);
      display: flex;
      justify-content: center;
      gap: var(--margin);
    }

    .contactBlock img {
      height: 75rem;
      max-width: 50%;
    }

    .contactBlock .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      min-width: 50%;
      flex-grow: 1;
    }

    .contactBlock .text h1 {
      color: var(--clr-gold) !important;
  }

  .contactBlock .text .addresses {
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
    width: 100%;
  }

  .contactBlock .text .addresses  p:first-child {
    color: var(--clr-gold);
    font-weight: 600;
  }

  .contactBlock .text .addresses p {
    color: var(--clr-black);
  }

  .contactBlock .text menu {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
  }

  .contactBlock .text menu a {
    height: fit-content;
    padding: 0;
  }
  .contactBlock .text menu a img {
    height: 15rem;
    margin: 0;
  }

    @media only screen and (max-width: 1150px) {
      .contactBlock {
          flex-direction: column-reverse;
          gap: var(--spacer);
      }

      .contactBlock img:not(a img) {
        min-width: 100% !important;
        object-fit: contain;
        height: auto;
      }

      .contactBlock .text menu a img {
        height: 15rem;
        margin: 0;
      }
    }