  .programBlock {
    display: flex;
    height: 60rem;
      align-items: center;
      justify-content: space-between;
      padding-inline: var(--margin);
      gap: var(--margin);
  }

  .programBlock .text {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacer);
    text-align: left;
    width: 50%;
    height: calc(100% - (2 * var(--spacer)));
  }
  .programBlock img {
    height: 100%;
    width: 50%;
    object-fit: cover;
  }

  .programBlock:not(:last-child) {
    padding-bottom: var(--margin);
  }

  @media only screen and (max-width: 1150px) {
    .programBlock {
        flex-direction: column-reverse !important;
        height: auto !important;
        padding-inline: 0;
    }

    .programBlock img {
      width: 100%;
      aspect-ratio: 1/1;
    }

    .programBlock .text {
      width: 100%;
      padding-inline: var(--margin);
    }

    .programBlock:not(:last-child) {
      padding-top: var(--padding);
    }

    .programBlock:last-child {
      padding-bottom: var(--margin);
    }

    .programBlock:first-child {
      padding-top: var(--margin);
    }
  }