.page-header {
    padding-inline: var(--margin);
  }

  .navbar.dtop-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--fs-16);
    height: 15rem;
    gap: var(--margin);
  }

   .navbar.dtop-only .navlinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacer);
  }

   .navbar.dtop-only li,
   .navbar.dtop-only li * {
    font-size: var(--fs-16);
    color: var(--clr-navy);
  }

   .navbar.dtop-only img {
    height: 7.5rem;
  }

  .navbar.mob-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--fs-16);
    height: 7.5rem;
    gap: var(--margin);
    background-color: var(--clr-white);
    padding-inline: var(--margin);
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition-property: transform;
    transition-duration: 200ms;
  }
  
  .navbar.mob-only img {
    height: 3.75rem;
  }

  .navbar.mob-only .hamburger {
    border: none;
    background-color: var(--clr-white);
    color: var(--clr-gold);
    font-size: 3.75rem;
  }