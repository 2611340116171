@font-face {
  font-family: Nunito-Sans;
  src: url(/src/fonts/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito-Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: clip;
}

main {
  min-height: calc(100svh - 15rem) !important;
}