    section.blog .blogPosts {
        display: grid;
        padding: var(--margin);
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: var(--spacer);
        min-height: 60rem;
      }

      section.blog .blogPosts h1 {
        color: var(--clr-navy);
      }

    section.blog .blogPosts .thumbnail {
      height: 60rem;
      object-fit: cover;
      background-color: var(--clr-lightblue);
    }

    section.blog .blogPosts img {
      width: 100%;
      height: 50%;
      object-fit: cover;
    }

    section.blog .blogPosts .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem;
      height: 50%;
      width: calc(100% - 4rem);
    }

    section.blog .blogPosts .text h1 {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    section.blog .blogPosts .text p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /* Number of lines */
      -webkit-box-orient: vertical;
    }

      @media only screen and (max-width: 1300px) {
        section.blog .blogPosts {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        section.blog .blogPosts .thumbnail {
          width: 100% !important;
        }
      }

      @media only screen and (max-width: 1150px) {
        section.blog .blogPosts .thumbnail {
          height: fit-content;
        }

        section.blog .blogPosts .thumbnail img {
          height: 30rem;
        }
      }

      @media only screen and (max-width: 900px) {
        section.blog .blogPosts {
          grid-template-columns: repeat(1, 1fr) !important;
        }

        section.blog .blogPosts .thumbnail {
          width: 100% !important;
        }
      }

      .searchBarInput {
        width: calc(100% - (var(--margin) * 2));
        margin-inline: var(--margin);
        margin-top: var(--margin);
        border: none;
        border-bottom: 1px solid var(--clr-navy);
        font-size: var(--fs-24);
        outline: none;
        line-height: 1.5em;
        border-radius: 0px;
      }