body {
    padding: 0;
    margin: 0;
  }

  html {
    padding: 0;
    margin: 0;
    font-size: 62.5%;
  }

  :root {
    --clr-gold: #c8a25f;
    --clr-darkgold: #997024;
    --clr-beige: #d6b18b;
    --clr-green: #7d9238;
    --clr-darkgreen: #595e29;
    --clr-navy: #2e4066;
    --clr-lightblue: #bfdaec;
    --clr-brown: #b06633;
    --clr-black: #353535;
    --clr-white: white;
    --clr-input: #a0a7b7;

    --margin: 7.5vw;
    --spacer: 3.75rem;
    --btn-br: 20px;

    --fs-36: 3.6rem;
    --fs-24: 2.4rem;
    --fs-18: 1.8rem;
    --fs-16: 1.6rem;
    --fs-14: 1.4rem;
    --fs-12: 1.2rem;
    --fs-10: 1rem;

    --slideDistance: calc((-1 * (100vw - (-2 * var(--spacer))) / 3));

  }

  * {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: var(--fs-36);
    font-weight: 900;
  }

  h2 {
    font-size: var(--fs-24);
    font-weight: 300;
  }

  p {
    font-size: var(--fs-24);
    font-weight: 300;
    color: var(--clr-black);
  }

  /* UTILITIES  */

  .display-none {
    display: none !important;
  }
  
  @media only screen and (min-width: 1150px) {
      .blocks > div[data-direction="imageRight"] {
        flex-direction: row-reverse;
      }
    
      .blocks > div[data-direction="imageLeft"] {
        flex-direction: row;
      }
  }
  
  .section-split {
    padding-inline: var(--margin);
    display: flex;
    align-items: center;
    gap: var(--spacer);
    overflow: hidden;
  }

  .section-split h1 {
    white-space: nowrap;
  }

  .section-split hr {
    transform: rotate(180deg);
    width: 100%;
    height: 0.4rem;
    border: 0;
  }
  
  .section-split a {
    text-decoration: none;
    color: inherit;
  }
  
  
  .bold {
    font-weight: 800;
  }
  
  .button {
    text-align: center;
    border-radius: var(--btn-br);
    border: 1px solid transparent;
    width: 10em;
    height: 2.85em;
    font-size: var(--fs-16);
    color: #ffffff;
    font-weight: 900;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button p {
    font-size: var(--fs-16) !important;
    color: #ffffff !important;
    font-weight: 900 !important;
  }
  
  button:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  
  .gold .button, .gold hr {
    background-color: var(--clr-gold);
  }
  
  .gold h1 {
    color: var(--clr-darkgold);
  }
  
  .brown .button, .brown hr {
    background-color: var(--clr-brown);
  }
  
  .brown h1 {
    color: var(--clr-brown);
  }

  .brown h2 {
    color: var(--clr-brown);
  }
  
  .green .button, .green hr {
    background-color: var(--clr-green);
  }
  
  .green h1 {
    color: var(--clr-darkgreen);
  }
  
  .navy h1 {
    color: var(--clr-navy);
  }
  
  .navy .button, .navy hr {
    background-color: var(--clr-navy);
  }
  
  .flex-row {
    display: flex;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .s-between {
    justify-content: space-between;
  }
  
  .g-spacer {
    gap: var(--spacer);
  }

      
  @keyframes slide-in {
    from {
        transform: translateX(100vw);
    }

    to {
      transform: translateX(0vw);

    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0vw);
    }

    to {
      transform: translateX(100vw);
    }
  }

  .slide-in {
    animation-name: slide-in;
    animation-duration: 500ms;
    animation-timing-function: ease;
  }

  .slide-out {
    animation-name: slide-out;
    animation-duration: 500ms;
    animation-timing-function: ease;
  }
  
  @media only screen and  (min-width: 1150px) {
    .mob-only {
      display: none !important;
    }
  }
  
  @media only screen and  (max-width: 1150px) {
    .dtop-only {
      display: none !important;
    }

    main {
      padding-top: 7.5rem;
    }
  }  
  
  .banner {
    width: 100%;
    aspect-ratio: 1920/675;
    position: relative;
  }

  .banner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (min-width: 1150px) {
    .slider {
    margin-block: var(--margin);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacer);
    width: 100vw;
    overflow-x: hidden;
    position: relative;
    transition: all 1s ease-in;
    }

    .slider a {
      margin-inline: auto;
    }
  
    .slider .slider-images {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      gap: calc(2 * var(--spacer));
      transition: all 300ms ease-in;
      height: 50rem;
    }

      
    .slider .slider-images .slider-image {
      width: calc((100vw - (4 * var(--spacer))) / 3);
      aspect-ratio: 1/1;
      overflow: hidden;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 300ms ease-in;
      opacity: 0.5;

    }
  
    .slider .active-height {
      transform: scale(1.2);
      background-position: center;
      opacity: 1 !important;
    }
  
    .slider .prevSlide {
      cursor: pointer;
      position: absolute;
      top: calc(50% - var(--spacer));
      left: calc((475/1920) * 100vw);
      width: calc((50/1920) * 100vw);
      aspect-ratio: 1/1.5;
      transform: translateY(-50%);
    }
  
    .slider .nextSlide {
      cursor: pointer;
      position: absolute;
      top: calc(50% - var(--spacer));
      right: calc((475/1920) * 100vw);
      width: calc((50/1920) * 100vw);
      aspect-ratio: 1/1.5;
      transform: translateY(-50%) rotate(180deg);
    }
  }
  
  @media only screen and (max-width: 1150px) {
    .slider {
      position: relative;
      width: 100%;
      height: 50rem !important;
      gap: var(--spacer);
      margin-block: calc(2 * var(--margin));
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 500ms ease-in;
    }

      
    .slider .slider-images {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      height: 100% !important;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-padding: 0;
      scroll-behavior: smooth;
      -webkit-scroll-color: black;
      }

      .slider .slider-images .slider-image {
        background-size: cover !important;
        background-repeat: no-repeat;
        scroll-snap-align: start;
        min-width: 100% !important;
      background-position: center;
    }
  }

  section:not(section.volunteer) .blocks {
      min-height: calc(100svh - 15rem) !important;
  }

  .blocks {
    margin-block: var(--margin);
  }

  @media only screen and (max-width: 1150px) {
    :root {
      --fs-36: 3.3rem;
      --fs-24: 2.2rem;
      --fs-18: 1.7rem;
      --fs-16: 1.5rem;
      --fs-14: 1.3rem;
      --fs-12: 1.1rem;
      --fs-10: 0.9rem;
      --spacer: 2rem;
    }
  }

  @media only screen and (max-width: 850px) {
    :root {
      --fs-36: 3.1rem;
      --fs-24: 2rem;
      --fs-18: 1.6rem;
      --fs-16: 1.4rem;
      --fs-14: 1.2rem;
      --fs-12: 1rem;
      --fs-10: 0.8rem;
      --spacer: 2rem;
    }
  }