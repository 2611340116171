.volunteerBlock {
    padding-inline: var(--margin);
    display: flex;
    height: 50rem;
    align-items: center;
    justify-content: space-between;
    padding-inline: var(--margin);
    gap: var(--margin);
}

.volunteerBlock:not(:first-of-type) {
  margin-top: var(--margin);
}

.volunteerBlock .text {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  text-align: left;
  height: calc(100% - (2 * var(--spacer)));
}
.volunteerBlock img {
  height: 100%;
  min-width: 50%;
  max-width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1150px) {
    .volunteerBlock {
        flex-direction: column-reverse;
        height: auto !important;
        padding-inline: 0;
    }

    .volunteerBlock .text {
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-inline: var(--margin);
  }

    .volunteerBlock img {
      aspect-ratio: 1/1 !important;
      width: calc(100% - var(--margin));
      padding-inline: var(--margin);
    }
}