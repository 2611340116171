.galleryContainer {
    display: grid;
    padding: var(--margin);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: var(--spacer);
  }

  .galleryContainer .galleryImage {
    width: calc((100vw - (2 * var(--margin)) - (2 * var(--spacer))) / 3);
    aspect-ratio: 1/1;
    object-fit: cover;
    cursor: pointer;
    transition: all 200ms ease-in;
  }

  .galleryContainer .galleryImage:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 1150px) {
    .galleryContainer {
      grid-template-columns: repeat(2, 1fr);
    }

    .galleryContainer .galleryImage {
      width: calc(
        (100vw - (2 * var(--margin)) - (1 * var(--spacer))) / 2
      );
    }
  }

  @media only screen and (max-width: 720px) {
    .galleryContainer {
      grid-template-columns: repeat(1, 1fr);
      gap: var(--margin);
    }

    .galleryContainer .galleryImage {
      width: calc((100vw - (2 * var(--margin))));
    }
  }


  .modal {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: var(--margin);
  }

  .modal img {
    max-width: 100%;
    max-height: 50%;
    object-fit: contain;
    margin-bottom: var(--spacer);
    margin-top: calc(0.5 * var(--spacer));
  }

  .modal h1, .modal p {
      color: var(--clr-white);
      text-align: center;
  }

  .modal button {
    background-color: var(--clr-navy);
  }