  .aboutBlock {
    width: 100%;
    box-sizing: border-box;
    padding-inline: var(--margin);
    display: flex;
    justify-content: flex-end;
    gap: var(--margin);
    position: relative;
    overflow: hidden;
  }

  .aboutBlock:not(:first-child) {
    padding-top: var(--margin);
  }
  
  .aboutBlock .text {
    display: flex;
    gap: var(--spacer);
    flex-direction: column;
    width: calc(50% - var(--spacer));
    padding-block: var(--spacer);
  }

  .aboutBlock img {
    width: calc(50% - var(--spacer) - var(--margin));
    height: 100%;
    left: var(--margin);
    position: absolute;
    object-fit: cover;
  }

  .teamMembers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--spacer);
    padding: var(--margin);
  }

  .teamMembers .teamMember {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 30%;
    min-width: 30rem;
  }

  .teamMembers .teamMember img {
    width: 70%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1150px) {
    .aboutBlock {
      flex-direction: column;
      align-items: center;
      padding-inline: 0;
    }

    .aboutBlock img {
      width: 100%;
      box-sizing: border-box;
      position: relative;
      left: 0;
    }

    .aboutBlock .text {
      width: calc(100% - (2 * var(--margin)));
    }
  }